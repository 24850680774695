import * as anchor from "@coral-xyz/anchor";
import { useEffect, useMemo, useState } from "react";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { loadProgram, getNetworkConnection, fetchProposals, fetchChoices, fetchVotes } from "../utils/utils";
import { Box, Button, Modal, Typography } from "@mui/material";
import ProposalForm from "../components/ProposalForm";
import ProposalList from "../components/ProposalList"
import ChoiceForm from "../components/ChoiceForm";
import ChoicesList from "../components/ChoicesList";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    bgcolor: '#333',
    border: '2px solid #666',
    boxShadow: 24,
    borderRadius: 2,
    p: 3,
    outline: 'none',
  };

const Home = () => {
    const [proposals, setProposals] = useState([]);
    const [choices, setChoices] = useState([]);
    const [votes, setVotes] = useState([]);
    const [formModalOpen, setFormModalOpen] = useState(false);
    const [selectedProposal, setSelectedProposal] = useState([]);
    const [choiceFormModalOpen, setChoiceFormModalOpen] = useState(false);
    const [choicesModalOpen, setChoicesModalOpen] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState([]);
    const [selectedChoiceProposal, setSelectedChoiceProposal] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const connection = getNetworkConnection(30);
    const anchorWallet = useAnchorWallet();

    const anchorProgram = useMemo(
        () => loadProgram(connection, anchorWallet!),
        [anchorWallet]
      );
    
    useEffect(() => {
        if (!anchorWallet?.publicKey || anchorWallet?.publicKey.toString() !== process.env.REACT_APP_ADMIN_WALLET) {
            return;
        }

        loadData();
    }, [anchorWallet]);

   const loadData = async() => {
       setIsLoading(true);
       try {
        // await loadChoices();
        // await loadVotes();
        await loadProposals();
       } catch (e) {
        console.log(e);
       } finally {
        setIsLoading(false);
       }
   }
   const loadProposals = async() => {
        const data: any = await fetchProposals(anchorProgram, anchorWallet!);
        setProposals(data);
    }

   const loadChoices = async() => {
        const data: any = await fetchChoices(anchorProgram, anchorWallet!);
        setChoices(data);
    }

    const loadVotes = async() => {
        const data: any = await fetchVotes(anchorProgram, anchorWallet!);
        setVotes(data);
    }

    const openEditModal = (proposal: any) => {
        for (let i = 0; i < proposals.length; i++) {
            // @ts-ignore
            if (proposals[i].publicKey.toString() === proposal.publicKey) {
                console.log('edit me', proposals[i]);
                setSelectedProposal(proposals[i]);
                setFormModalOpen(true);
            }
        }
    }

    const openCreateModal = () => {
        setSelectedProposal([]);
        setFormModalOpen(true);
    }

    const handleModalClose = () => {
        setFormModalOpen(false);
        loadData();
    }

    const handleChoiceModalClose = async() => {
        setChoiceFormModalOpen(false);   
        loadData();     
    }

    const handleChoicesModalClose = () => {
        setChoicesModalOpen(false);
        loadData();
    }

    const openCreateChoiceModal = (proposal: any) => {
        setSelectedChoice([]);
        setSelectedChoiceProposal(proposal);
        setChoiceFormModalOpen(true);
        // setChoicesModalOpen(false);
    }

    const openUpdateChoiceModal = (choice: any) => {
        console.log(`update choice`, choice);
        setSelectedChoice(choice);
        // setSelectedChoiceProposal(proposal);
        setChoicesModalOpen(false);
        setChoiceFormModalOpen(true);
    }

    const openViewChoicesModal = (proposal: any) => {
        setSelectedChoiceProposal(proposal);
        setChoicesModalOpen(true);
    }

  return (
      <>
        {(anchorWallet?.publicKey && anchorWallet?.publicKey.toString() === process.env.REACT_APP_ADMIN_WALLET) &&

            <Box mt={7} mb={3} display="flex" justifyContent="space-between" alignItems="center">
            {/* <Typography variant="h6">Proposals</Typography> */}
            <Button variant="contained" onClick={openCreateModal}>new proposal</Button>
        </Box>}

        <Box>
            <ProposalList 
                proposals={proposals} 
                choices={choices} 
                votes={votes} 
                isLoading={isLoading} 
                openEditModal={openEditModal} 
                openCreateChoiceModal={openCreateChoiceModal} 
                openUpdateChoiceModal={openUpdateChoiceModal} 
                openViewChoicesModal={openViewChoicesModal} />
        </Box>

        <Modal
            open={formModalOpen}
            onClose={handleModalClose}
        >
            <Box sx={modalStyle}>
                <ProposalForm proposal={selectedProposal} closeModal={handleModalClose} />
            </Box>
        </Modal>


        <Modal
            open={choiceFormModalOpen}
            onClose={handleChoiceModalClose}
        >
            <Box sx={modalStyle}>
                <ChoiceForm 
                    choice={selectedChoice} 
                    proposal={selectedChoiceProposal} 
                    loadChoices={loadChoices}
                    closeModal={handleChoiceModalClose} 
                    handleChoiceModalClose={handleChoiceModalClose} />
            </Box>
        </Modal>

        <Modal
            open={choicesModalOpen}
            onClose={handleChoicesModalClose}
        >
            <Box sx={modalStyle}>
                <ChoicesList proposal={selectedChoiceProposal} openUpdateChoiceModal={openUpdateChoiceModal} openCreateChoiceModal={openCreateChoiceModal} loadData={loadData} />
            </Box>
        </Modal>
    </>
  );
};

export default Home;

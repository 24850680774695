import * as anchor from "@coral-xyz/anchor";
import { useEffect, useState } from "react";
import { Box, Button, TextField, FormControl, Grid, ButtonGroup, Stack, List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import { Typography } from "@mui/material";
import { buildAddChoiceInstruction, buildDeleteChoiceInstruction, getNetworkConnection, loadProgram, sendAndConfirmTransactionListCustom1 } from "../utils/utils";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

const ChoiceView = (props: any) => {
    return (
        <>
            <List>
                <ListItem>
                    <ListItemButton onClick={() => props.openUpdateChoiceModal(props.choice)}>
                        {props.choice.metadata && <h3>{props.choice.metadata.title}</h3>}
                        {!props.choice.metadata && <h3>{props.choice.account.title}</h3>}
                    </ListItemButton>
                </ListItem> 
            </List>
            
        </>
    )
}

const ChoicesList = (props: any) => {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>('save');
    const [deleteButtonText, setDeleteButtonText] = useState<string>('delete');
    const [header, setHeader] = useState<string>('New Proposal');

    const [proposal, setProposal] = useState<any>({});
    const [title, setTitle] = useState<string>('');
    const [subTitle, setSubTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');

    useEffect(() => {
        // if (props.choice && Object.keys(props.choice).length) {
        //     setIsEditing(true);
        //     setButtonText('save');

        //     // setProposal(props.choice.proposal);
        //     setTitle(props.choice.metadata.title);
        //     setSubTitle(props.choice.metadata.subTitle);
        //     setDescription(props.choice.metadata.description);
        //     setImageUrl(props.choice.metadata.imageUrl);
        // } else {
        //     setIsEditing(false);
        //     setButtonText('create');
        // }
    }, [props.choice]);

    useEffect(() => {
        if (props.proposal && Object.keys(props.proposal).length) {
            console.log(props.proposal);
            setProposal(props.proposal);
            setHeader(`${props.proposal.name} choices`);
        } 
    }, [props.proposal]);

    const anchorWallet = useAnchorWallet();

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value);
    }

    const handleSubTitleChange = (e: any) => {
        setSubTitle(e.target.value);
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value);
    }

    const handleImageUrlChange = (e: any) => {
        setImageUrl(e.target.value);
    }

    const deleteChoice = async() => {
        if (!anchorWallet) {
            return;
        }

        setIsDeleting(true);

        try {
            setDeleteButtonText('deleting...');

            const choiceData = {
                choiceTitle: props.proposal.account.title.toString(),
            }
            console.log(choiceData);

            const connection = getNetworkConnection(30);
            const anchorProgram = loadProgram(connection, anchorWallet!);
            const ix = await buildDeleteChoiceInstruction(anchorProgram, choiceData);

            console.log(ix);
            const txn = new anchor.web3.Transaction();
            txn.add(ix);

            await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn]);
            if (props.closeModal) {
                props.closeModal();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsDeleting(false);
            setDeleteButtonText('delete');
        }
    }

    return (
      <Box>
          <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">{header}</Typography>
              <Button variant="contained" onClick={() => props.openCreateChoiceModal(proposal)}>NEW CHOICE</Button>
          </Box>
          {proposal.choices && proposal.choices.map((choice: any, index: number) => 
            <ChoiceView choice={choice} openUpdateChoiceModal={props.openUpdateChoiceModal} />
            )}
          {/* <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Title" variant="outlined" size="small" value={title} onChange={handleTitleChange} />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Subtitle" variant="outlined" size="small" value={subTitle} onChange={handleSubTitleChange} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="none">
                    <TextField label="Description" variant="outlined" multiline rows={3} size="small" value={description} onChange={handleDescriptionChange} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="none">
                    <TextField label="Image URL" variant="outlined" size="small" value={imageUrl} onChange={handleImageUrlChange} />
                </FormControl>
              </Grid>

          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={2}>
              <Stack direction="row" spacing={2}>
                {isEditing && <Button variant="contained" color="error" disabled={isDeleting} onClick={deleteChoice}>{deleteButtonText}</Button>}
                {isEditing ? 
                    <Button variant="contained" disabled={isSaving} onClick={createChoice}>{buttonText}</Button>
                    :
                    <Button variant="contained" disabled={isSaving} onClick={createChoice}>{buttonText}</Button>
                }
              </Stack>
          </Box> */}
      </Box>
    );
  };
  
  export default ChoicesList;
  
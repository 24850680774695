import * as anchor from "@coral-xyz/anchor";
import { useEffect, useState } from "react";
import { Box, Button, TextField, FormControl, Grid, ButtonGroup, Stack, List, ListItem, ListItemText, IconButton, Select, InputLabel, MenuItem } from "@mui/material";
import { Typography } from "@mui/material";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useCookies } from "react-cookie";

const ChoiceLinkForm = (props: any) => {
    const [buttonText, setButtonText] = useState<string>('add link');
    const [header, setHeader] = useState<string>('Choice Link');

    const [newLinkUrl, setNewLinkUrl] = useState<string>('');
    const [newLinkName, setNewLinkName] = useState<string>('');
    const [newLinkType, setNewLinkType] = useState<string>('');

    const handleNewLinkUrlChange = (e: any) => {
        setNewLinkUrl(e.target.value);
    }

    const handleNewLinkNameChange = (e: any) => {
        setNewLinkName(e.target.value);
    }

    const handleNewLinkTypeChange = (e: any) => {
        setNewLinkType(e.target.value);
    }

    const cancelChoiceLinkAction = () => {
        props.handleLinkModalClose();
    }

    const saveChoiceLink = async() => {
        const links = props.links;
        links.push({ url: newLinkUrl, name: newLinkName, type: newLinkType });
        props.setLinks(links);

        props.handleLinkModalClose();

    }

    return (
      <Box>
          <Typography variant="h5">{header}</Typography>
          
            <Box>
                <FormControl fullWidth margin="normal">
                    <TextField label="Link URL" variant="outlined" size="small" value={newLinkUrl} onChange={handleNewLinkUrlChange} />
                </FormControl>
                
                <FormControl fullWidth margin="normal">
                    <TextField label="Name" variant="outlined" size="small" value={newLinkName} onChange={handleNewLinkNameChange} />
                </FormControl>

                <FormControl fullWidth size="small" margin="normal">
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="demo-simple-select"
                        value={newLinkType}
                        label="Type"
                        onChange={handleNewLinkTypeChange}
                    >
                        <MenuItem value="twitter">Twitter</MenuItem>
                        <MenuItem value="instagram">Instagram</MenuItem>
                        <MenuItem value="linkedin">LinkedIn</MenuItem>
                        <MenuItem value="pdf">PDF</MenuItem>
                        <MenuItem value="document">Document</MenuItem>
                        <MenuItem value="website">Website</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box display="flex" justifyContent="space-between" mt={2}>
              <Box>
                <Button onClick={cancelChoiceLinkAction}>Cancel</Button>
              </Box>
              <Stack direction="row" spacing={2}>
                
                 <Button variant="contained" onClick={saveChoiceLink}>{buttonText}</Button>
              </Stack>
          </Box>

      </Box>
    );
  };
  
  export default ChoiceLinkForm;
  
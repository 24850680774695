import { Card, Skeleton, Grid, CardContent, CardHeader, CardMedia } from "@mui/material"

function EmptyProposalCard() {
    return <Grid item xs={6}>
            <Card variant="outlined">
              <CardContent>
                <Skeleton animation="wave" height={300} />
                <Skeleton animation="wave" height={250} />
              </CardContent>
            </Card>
  </Grid>;
}

export default EmptyProposalCard;
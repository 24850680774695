import * as anchor from "@coral-xyz/anchor";
import { useEffect, useState } from "react";
import { Box, Button, TextField, FormControl, Grid, Modal, Stack, List, ListItem, ListItemIcon, ListItemText, IconButton, Select, InputLabel, MenuItem } from "@mui/material";
import { Typography } from "@mui/material";
import { buildAddChoiceInstruction, buildDeleteChoiceInstruction, getNetworkConnection, getProposalPDA, loadProgram, sendAndConfirmTransactionListCustom1 } from "../utils/utils";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useCookies } from "react-cookie";
import { ChoiceLink } from "../utils/types";
import ChoiceLinkForm from "./ChoiceLinkForm";
import RemoveIcon from '@mui/icons-material/Delete';
import OpenIcon from '@mui/icons-material/OpenInNew';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import DocumentIcon from '@mui/icons-material/Article';
import WebsiteIcon from '@mui/icons-material/Web';
import AddIcon from "@mui/icons-material/Add";

const linkModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    bgcolor: '#333',
    border: '2px solid #666',
    boxShadow: 24,
    borderRadius: 2,
    p: 3,
    outline: 'none',
  };

const ChoiceForm = (props: any) => {
    const [cookies] = useCookies();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>('save');
    const [deleteButtonText, setDeleteButtonText] = useState<string>('delete');
    const [header, setHeader] = useState<string>('Choice');

    const [proposal, setProposal] = useState<any>({});
    const [title, setTitle] = useState<string>('');
    const [subTitle, setSubTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [links, setLinks] = useState<ChoiceLink[]>([ ]);
    const [linkModalOpen, setLinkModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.choice && Object.keys(props.choice).length) {
            setIsEditing(true);
            setButtonText('save');

            // setProposal(props.choice.proposal);
            setTitle(props.choice.metadata.title);
            setSubTitle(props.choice.metadata.subTitle);
            setDescription(props.choice.metadata.description);
            setImageUrl(props.choice.metadata.imageUrl);
            if (props.choice.metadata.links) {
                setLinks(props.choice.metadata.links);
            }
        } else {
            setIsEditing(false);
            setButtonText('create');
        }
    }, [props.choice]);

    const anchorWallet = useAnchorWallet();

    const handleLinkModalClose = () => {
        setLinkModalOpen(false);
    }

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value);
    }

    const handleSubTitleChange = (e: any) => {
        setSubTitle(e.target.value);
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value);
    }

    const handleImageUrlChange = (e: any) => {
        setImageUrl(e.target.value);
    }

    const handleLinkRemove = (index: number) => {
        let newLinks = [...links];

        newLinks.splice(index, 1);
        
        setLinks(newLinks);
    }

    const getLinkIcon = (type: string) => {
        if (type === 'twitter')
            return <TwitterIcon />;
        if (type === 'instagram')
            return <InstagramIcon />;
        if (type === 'linkedin')
            return <LinkedInIcon />;
        if (type === 'pdf')
            return <PDFIcon />;
        if (type === 'document')
            return <DocumentIcon />;
        if (type === 'website')
            return <WebsiteIcon />;
    }

    const createChoice = async () => {
        if (!anchorWallet) {
            return;
        }

        setIsSaving(true);
        setButtonText('creating metadata...');

        try {
            const metadata = await createMetadata();
            console.log(metadata);

            setButtonText('creating choice...');

            const connection = getNetworkConnection(30);
            const anchorProgram = loadProgram(connection, anchorWallet!);
            console.log(`choice prop`, props, proposal);

            const choiceData = {
                proposal: props.proposal.publicKey,
                proposalTitle: props.proposal.proposal.title,
                choiceTitle: title,
                title: title,
                metadataUri: metadata.metadata_uri,
            }
            console.log(`choice data`, choiceData);

            const ix = await buildAddChoiceInstruction(anchorProgram, choiceData);

            console.log(ix);
            const txn = new anchor.web3.Transaction();
            txn.add(ix);
        
            await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn]);
            
            props.handleChoiceModalClose();
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
            
        }
    }

    const createMetadata = async () => {
        // create metadata file, upload to shadow via admin api
        const metadata = {
            title,
            subTitle,
            description,
            imageUrl,
            links,
        };

        let proposalKey;

        if (props.proposal.publicKey) {
            proposalKey = props.proposal.publicKey.toString();
        }

        // create proposalTitle
        let choiceTitle = `choice-${Date.now()}`;
        let payload = {choiceTitle, ...metadata, proposalKey};

        let url = `${process.env.REACT_APP_VOTING_API_URL}/api/metadata/choice`;

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer: ${cookies.token}`,
            }
        });

        const responseJson = await response.json();

        console.log(responseJson);
        
        return responseJson;
    }

    const updateChoice = async () => {
        if (!anchorWallet) {
            return;
        }

        setIsSaving(true);
        setButtonText('saving metadata...');

        try {
            const metadata = await updateMetadata();
            console.log(metadata);

            props.closeModal();
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
            setButtonText('save');
        }
    }

    const updateMetadata = async () => {
        const metadata = {
            title,
            subTitle,
            description,
            imageUrl,
            links,
        };

        let choicePubKey = props.choice.publicKey.toString();
        let payload = { metadataUri: props.choice.account.metadataUri, ...metadata};

        let url = `${process.env.REACT_APP_VOTING_API_URL}/api/metadata/choice/${choicePubKey}`;

        console.log(payload);

        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer: ${cookies.token}`,
            }
        });

        const responseJson = await response.json();

        console.log(responseJson);
        
        return responseJson;
    }

    const deleteChoice = async() => {
        if (!anchorWallet) {
            return;
        }

        setIsDeleting(true);
        setIsSaving(true);

        try {
            setDeleteButtonText('deleting...');
            console.log(`my props`, props);
            const choiceData = {
                choiceTitle: props.choice.account.title.toString(),
                proposal: props.proposal.publicKey,
                proposalTitle: props.proposal.proposal.title,
                choiceKey: props.choice.publicKey.toString(),
            }
            console.log(`delete choice`, choiceData);

            const connection = getNetworkConnection(30);
            const anchorProgram = loadProgram(connection, anchorWallet!);
            const ix = await buildDeleteChoiceInstruction(anchorProgram, choiceData);

            console.log(ix);
            const txn = new anchor.web3.Transaction();
            txn.add(ix);

            await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn]);
            if (props.closeModal) {
                props.closeModal();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
            setIsDeleting(false);
            setDeleteButtonText('delete');
        }
    }

    const cancelChoiceAction = () => {
        props.handleChoiceModalClose();
    }

    return (
      <Box>
          <Typography variant="h5">{header}</Typography>
          <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Title" variant="outlined" size="small" value={title} onChange={handleTitleChange} />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Subtitle" variant="outlined" size="small" value={subTitle} onChange={handleSubTitleChange} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="none">
                    <TextField label="Description" variant="outlined" multiline rows={3} size="small" value={description} onChange={handleDescriptionChange} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="none">
                    <TextField label="Image URL" variant="outlined" size="small" value={imageUrl} onChange={handleImageUrlChange} />
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <List>
                    <ListItem secondaryAction={
                        <IconButton edge="end" onClick={() => setLinkModalOpen(true)}>
                        <AddIcon />
                        </IconButton>
                    }>
                        <ListItemText primary="Links" />
                    </ListItem>

                    {links && links.map((link: ChoiceLink, index: number) => 
                        <ListItem key={'link-'+index}
                            secondaryAction={<>
                                    <IconButton edge="end" sx={{ marginRight: 1 }} onClick={() => window.open(link.url, "_blank")}>
                                      <OpenIcon />
                                    </IconButton>
                                    <IconButton edge="end"  onClick={() => handleLinkRemove(index)}>
                                      <RemoveIcon />
                                    </IconButton>
                                    </>
                            }>
                                {link.type && <ListItemIcon>
                                    {getLinkIcon(link.type ?? '')}
                                </ListItemIcon>}
                            <ListItemText primary={
                                    <Typography>{ link.name ?? link.url } </Typography>
                                }
                                 />
                        </ListItem>)}
                </List>
              </Grid>

          </Grid>
          <Box display="flex" justifyContent="space-between" mt={2}>
              <Box>
                <Button onClick={cancelChoiceAction}>Cancel</Button>
              </Box>
              <Stack direction="row" spacing={2}>
                {isEditing && <Button variant="contained" color="error" disabled={isDeleting} onClick={deleteChoice}>{deleteButtonText}</Button>}
                
                 <Button variant="contained" disabled={isSaving} onClick={isEditing ? updateChoice : createChoice}>{buttonText}</Button>
              </Stack>
          </Box>

          <Modal
            open={linkModalOpen}
            onClose={handleLinkModalClose}
        >
            <Box sx={linkModalStyle}>
                <ChoiceLinkForm links={links} setLinks={setLinks} handleLinkModalClose={handleLinkModalClose} />
            </Box>
        </Modal>
      </Box>
    );
  };
  
  export default ChoiceForm;
  
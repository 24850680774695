import { Box, Card, CardHeader, CardContent, CardMedia, Chip, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemButton, ListItemText, IconButton, Stack, Paper } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import YesIcon from '@mui/icons-material/Check';
import NoIcon from '@mui/icons-material/Close';
import { green, red } from '@mui/material/colors';

const ProposalCard = (props: any) => {
    const row = props.row;

    const now = Math.floor(Date.now() / 1000);
    const start = row.proposal.startTime.toNumber();
    const end = row.proposal.endTime.toNumber();

    let status;

    if (now > start && now > end) {
        status = 'Completed';
    } else if (now > start && now < end) {
        status = 'Active';
    } else if (now < start) {
        status = 'Upcoming';
    }

    let visibility = row.proposal.isHidden ? 'Hidden' : 'Visible';

    console.log(`row`, row)

    const isVideo = (url: string) => {
      return url.indexOf('youtube') !== -1 || url.indexOf('vimeo') !== -1;
    }

    return (
      <Card variant="outlined">
        <CardHeader title={<>
                    {row.name}
                    <Chip label={status} sx={{ marginLeft: 2 }} />
                    <Chip label={visibility} sx={{ marginLeft: 2 }} />

                    </>}
                    subheader={<>
                    {row.start} - {row.end}
                    </>}
                    action={
                        <IconButton edge="end" onClick={() => props.handleEditProposalClick(props.index)} sx={{ marginRight: 1 }}>
                          <EditIcon />
                        </IconButton>
                      } />

{(row.metadata.imageUrl && !isVideo(row.metadata.imageUrl)) && <Paper variant="outlined" sx={{ marginX: 2 }}>
            <img width="100%" src={row.metadata.imageUrl} />
        </Paper>}

        {(row.metadata.imageUrl && isVideo(row.metadata.imageUrl)) && 
          <CardMedia
            src={row.metadata.imageUrl}
            height="300"
            component="iframe" />}

        <CardContent>
            <Stack direction="row">
                <Typography sx={{ fontSize: 14 }} >
                    {row.metadata.subTitle}
                    {/* {row.start} - {row.end} */}
                </Typography>
            </Stack>
            

        <Typography variant="body2" mb={3} color="text.secondary">
        <div
                        dangerouslySetInnerHTML={{__html: row.metadata.description}}
                />

          {/* {row.metadata.description} */}
        </Typography>

        <List>
            <ListItem secondaryAction={
                    <IconButton edge="end" onClick={() => props.openCreateChoiceModal(props.row)}>
                      <AddIcon />
                    </IconButton>
                  }>
                <ListItemText primary="Choices" />
            </ListItem>
            {row.choices && row.choices.map((choice: any, index: number) =>
                <ListItem secondaryAction={
                    <IconButton edge="end" onClick={() => props.handleEditChoiceClick(choice)}>
                      <EditIcon />
                    </IconButton>
                  }>
                      {choice.metadata.imageUrl && <ListItemAvatar>
                         <Avatar src={choice.metadata.imageUrl} />
                        </ListItemAvatar>}

                      {(choice.metadata.title === 'Yes' && !choice.metadata.imageUrl) && 
                      <ListItemAvatar>
                      <Avatar sx={{ bgcolor: green[400] }}>
                        <YesIcon />
                      </Avatar>
                     </ListItemAvatar>}
                      
                     {(choice.metadata.title === 'No' && !choice.metadata.imageUrl) && 
                      <ListItemAvatar>
                      <Avatar sx={{ bgcolor: red[400] }}>
                        <NoIcon />
                      </Avatar>
                     </ListItemAvatar>}

                    <ListItemText 
                        primary={
                            <Box display="flex">
                                <Typography mr={2}>{choice.metadata.title}</Typography>
                                <Chip size="small" label={choice.account.tally.toNumber() === 1 ? '1 vote' : choice.account.tally.toNumber() + ' votes'} />
                            </Box>
                        } 
                        secondary={choice.metadata.subTitle} />
                </ListItem>
            )}
        </List>
      </CardContent>
      </Card>
    )
  }

export default ProposalCard;
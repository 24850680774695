import * as anchor from "@coral-xyz/anchor";
import { useEffect, useState } from "react";
import { Box, Button, TextField, FormControl, Grid, List, ListItem, ListItemText, ListItemIcon, Modal, IconButton, Stack, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { Typography } from "@mui/material";
import { buildInitProposalInstruction, buildUpdateProposalInstruction, buildDeleteProposalInstruction, getNetworkConnection, loadProgram, sendAndConfirmTransactionListCustom1 } from "../utils/utils";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from "dayjs";
import { useCookies } from "react-cookie";

import { ProposalLink } from "../utils/types";
import ProposalLinkForm from "./ProposalLinkForm";
import RemoveIcon from '@mui/icons-material/Delete';
import OpenIcon from '@mui/icons-material/OpenInNew';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import DocumentIcon from '@mui/icons-material/Article';
import WebsiteIcon from '@mui/icons-material/Web';
import AddIcon from "@mui/icons-material/Add";

const linkModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    bgcolor: '#333',
    border: '2px solid #666',
    boxShadow: 24,
    borderRadius: 2,
    p: 3,
    outline: 'none',
  };
  
const ProposalForm = (props: any) => {
    const [cookies] = useCookies();

    const defaultVerifiedCreator = process.env.REACT_APP_VERIFIED_CREATOR ?? '';
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>('save');
    const [deleteButtonText, setDeleteButtonText] = useState<string>('delete');
    const [header, setHeader] = useState<string>('New Proposal');

    const [title, setTitle] = useState<string>('');
    const [subTitle, setSubTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [links, setLinks] = useState<ProposalLink[]>([ ]);
    const [linkModalOpen, setLinkModalOpen] = useState<boolean>(false);

    const [startTime, setStartTime] = useState<Dayjs | null>(dayjs());
    const [endTime, setEndTime] = useState<Dayjs | null>(dayjs());
    const [verifiedCreator, setVerifiedCreator] = useState<string>(defaultVerifiedCreator);
    const [daysStaked, setDaysStaked] = useState<number | null>(0);
    const [quorum, setQuorum] = useState<number | null>(0);
    const [isHidden, setIsHidden] = useState<boolean>(false);

    useEffect(() => {
        if (props.proposal && Object.keys(props.proposal).length) {
            setIsEditing(true);
            setButtonText('save');

            if (props.proposal.metadata) {
                setHeader(props.proposal.metadata.title);
                setTitle(props.proposal.metadata.title);
                setSubTitle(props.proposal.metadata.subTitle);
                setDescription(props.proposal.metadata.description);
                setImageUrl(props.proposal.metadata.imageUrl);
                if (props.proposal.metadata.links) {
                    setLinks(props.proposal.metadata.links);
                }
            }

            if (props.proposal.account.isHidden) {
                setIsHidden(true);
            }

            if (props.proposal.account.startTime) {
                let start = dayjs.unix(props.proposal.account.startTime.toNumber());
                setStartTime(start);
            }
            if (props.proposal.account.endTime) {
                let end = dayjs.unix(props.proposal.account.endTime.toNumber());
                setEndTime(end);
            }

            if (props.proposal.account.verifiedCreator) {
                setVerifiedCreator(props.proposal.account.verifiedCreator.toString());
            }

            if (props.proposal.account.requiredStakeDays) {
                setDaysStaked(props.proposal.account.requiredStakeDays.toNumber());
            }

            if (props.proposal.account.quorum) {
                setQuorum(props.proposal.account.quorum.toNumber());
            }

        } else {
            setIsEditing(false);
            setButtonText('create');
        }
    }, [props.proposal]);

    const anchorWallet = useAnchorWallet();

    const handleLinkModalClose = () => {
        setLinkModalOpen(false);
    }

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value);
    }

    const handleSubTitleChange = (e: any) => {
        setSubTitle(e.target.value);
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value);
    }

    const handleImageUrlChange = (e: any) => {
        setImageUrl(e.target.value);
    }

    const handleStartTimeChange = (e: any) => {
        setStartTime(e);
    }

    const handleEndTimeChange = (e: any) => {
        setEndTime(e);
    }

    const handleVerifiedCreatorChange = (e: any) => {
        setVerifiedCreator(e.target.value);
    }

    const handleDaysStakedChange = (e: any) => {
        if (!e.target.value) {
            setDaysStaked(0); 
        } else {
            setDaysStaked(parseInt(e.target.value));
        }
    }

    const handleQuorumChange = (e: any) => {
        if (!e.target.value) {
            setQuorum(0);
        } else {
            setQuorum(parseInt(e.target.value));
        }
    }

    const handleIsHiddenChange = (e: any) => {
        setIsHidden(e.target.checked);
    }

    const handleLinkRemove = (index: number) => {
        let newLinks = [...links];

        newLinks.splice(index, 1);
        
        setLinks(newLinks);
    }

    const getLinkIcon = (type: string) => {
        if (type === 'twitter')
            return <TwitterIcon />;
        if (type === 'instagram')
            return <InstagramIcon />;
        if (type === 'linkedin')
            return <LinkedInIcon />;
        if (type === 'pdf')
            return <PDFIcon />;
        if (type === 'document')
            return <DocumentIcon />;
        if (type === 'website')
            return <WebsiteIcon />;
    }

    const createProposal = async () => {
        if (!anchorWallet) {
            return;
        }

        setIsSaving(true);
        setButtonText('creating metadata...');

        try {
            const metadata = await createMetadata();
            console.log(metadata);

            setButtonText('creating proposal...');

            let start = startTime ? startTime.unix() : Math.floor(Date.now() / 1000);
            let end = endTime ? endTime.unix() : Math.floor(Date.now() / 1000);

            const proposalData = {
                proposalTitle: title,
                title: title,
                startTime: start,
                endTime: end,
                metadataUri: metadata.metadata_uri,
                verifiedCreator: verifiedCreator,
                isHidden: isHidden,
                requiredStakeDays: daysStaked,
                quorum: quorum,
            }
            console.log(proposalData);

            const connection = getNetworkConnection(30);
            const anchorProgram = loadProgram(connection, anchorWallet!);
            const ix = await buildInitProposalInstruction(anchorProgram, proposalData);

            console.log(ix);
            const txn = new anchor.web3.Transaction();
            txn.add(ix);
        
            await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn]);
            if (props.closeModal) {
                props.closeModal();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
            
        }
    }

    const createMetadata = async () => {
        // create metadata file, upload to shadow via admin api
        const metadata = {
            title,
            subTitle,
            description,
            imageUrl,
            links,
        };

        // create proposalTitle
        let proposalTitle = `proposal-${Date.now()}`;
        let payload = {proposalTitle, ...metadata};

        let url = `${process.env.REACT_APP_VOTING_API_URL}/api/metadata/proposal`;

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer: ${cookies.token}`,
            }
        });

        const responseJson = await response.json();

        console.log(responseJson);
        
        return responseJson;
    }

    const updateProposal = async () => {
        if (!anchorWallet) {
            return;
        }

        setIsSaving(true);
        setButtonText('saving metadata...');

        try {
            const metadata = await updateMetadata();
            console.log(metadata);

            setButtonText('saving proposal...');

            let start = startTime ? startTime.unix() : Math.floor(Date.now() / 1000);
            let end = endTime ? endTime.unix() : Math.floor(Date.now() / 1000);

            const proposalData = {
                proposalTitle: props.proposal.account.title.toString(),
                startTime: start,
                endTime: end,
                metadataUri: metadata.metadata_uri,
                verifiedCreator: verifiedCreator,
                isHidden: isHidden,
                requiredStakeDays: daysStaked,
                quorum: quorum,
            }
            console.log(`proposalData`, proposalData);

            const connection = getNetworkConnection(30);
            const anchorProgram = loadProgram(connection, anchorWallet!);
            const ix = await buildUpdateProposalInstruction(anchorProgram, proposalData);

            console.log(ix);
            const txn = new anchor.web3.Transaction();
            txn.add(ix);

            await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn]);
            if (props.closeModal) {
                props.closeModal();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
            setButtonText('save');
        }
    }

    const updateMetadata = async () => {
        const metadata = {
            title,
            subTitle,
            description,
            imageUrl,
            links,
        };

        let proposalPubKey = props.proposal.publicKey.toString();
        let payload = { metadataUri: props.proposal.account.metadataUri, ...metadata};

        let url = `${process.env.REACT_APP_VOTING_API_URL}/api/metadata/proposal/${proposalPubKey}`;
        console.log(url);
        console.log(payload);
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer: ${cookies.token}`,
            }
        });

        const responseJson = await response.json();

        console.log(responseJson);
        
        return responseJson;
    }

    const deleteProposal = async() => {
        if (!anchorWallet) {
            return;
        }

        setIsDeleting(true);
        setIsSaving(true);

        try {
            setDeleteButtonText('deleting...');

            const proposalData = {
                proposalTitle: props.proposal.account.title.toString(),
            }
            console.log(proposalData);

            const connection = getNetworkConnection(30);
            const anchorProgram = loadProgram(connection, anchorWallet!);
            const ix = await buildDeleteProposalInstruction(anchorProgram, proposalData);

            console.log(ix);
            const txn = new anchor.web3.Transaction();
            txn.add(ix);

            await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn]);
            if (props.closeModal) {
                props.closeModal();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsSaving(false);
            setIsDeleting(false);
            setDeleteButtonText('delete');
        }
    }

    const cancelProposalAction = () => {
        props.handleProposalModalClose();
    }

    return (
      <Box>
          <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">{header}</Typography>

            <FormControlLabel label="Hidden" control={
                <Switch size="small" checked={isHidden} onChange={handleIsHiddenChange} />
            } />
          </Box>
          <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Title" variant="outlined" size="small" value={title} onChange={handleTitleChange} />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Subtitle" variant="outlined" size="small" value={subTitle} onChange={handleSubTitleChange} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="none">
                    <TextField label="Description" variant="outlined" multiline rows={3} size="small" value={description} onChange={handleDescriptionChange} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="none">
                    <TextField label="Image URL" variant="outlined" size="small" value={imageUrl} onChange={handleImageUrlChange} />
                </FormControl>
              </Grid>

               <Grid item xs={6}>
               <FormControl fullWidth margin="none">

               <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker label="Start Time" value={dayjs(startTime)} onChange={handleStartTimeChange} />
                </LocalizationProvider>
                </FormControl>
               </Grid>

               <Grid item xs={6}>
               <FormControl fullWidth margin="none">
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker label="End Time" value={dayjs(endTime)} onChange={handleEndTimeChange} />
                </LocalizationProvider>
                </FormControl>
               </Grid>
{/* 
              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Start Time" variant="outlined" size="small" value={startTime} onChange={handleStartTimeChange} />
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="End Time" variant="outlined" size="small" value={endTime} onChange={handleEndTimeChange} />
                </FormControl>
              </Grid> */}

              <Grid item xs={6}>
                <FormControl fullWidth margin="none">
                    <TextField label="Verified Creator" variant="outlined" size="small" value={verifiedCreator} onChange={handleVerifiedCreatorChange} />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth margin="none">
                    <TextField label="Days Staked Required" variant="outlined" size="small" value={daysStaked} onChange={handleDaysStakedChange} />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth margin="none">
                    <TextField label="Quorum (number of votes)" variant="outlined" size="small" value={quorum} onChange={handleQuorumChange} />
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <List>
                    <ListItem secondaryAction={
                        <IconButton edge="end" onClick={() => setLinkModalOpen(true)}>
                        <AddIcon />
                        </IconButton>
                    }>
                        <ListItemText primary="Links" />
                    </ListItem>

                    {links && links.map((link: ProposalLink, index: number) => 
                        <ListItem key={'link-'+index}
                            secondaryAction={<>
                                    <IconButton edge="end" sx={{ marginRight: 1 }} onClick={() => window.open(link.url, "_blank")}>
                                      <OpenIcon />
                                    </IconButton>
                                    <IconButton edge="end"  onClick={() => handleLinkRemove(index)}>
                                      <RemoveIcon />
                                    </IconButton>
                                    </>
                            }>
                                {link.type && <ListItemIcon>
                                    {getLinkIcon(link.type ?? '')}
                                </ListItemIcon>}
                            <ListItemText primary={
                                    <Typography>{ link.name ?? link.url } </Typography>
                                }
                                 />
                        </ListItem>)}
                </List>
              </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={2}>
              <Stack direction="row" spacing={2}>
                {isEditing && <Button variant="contained" color="error" disabled={isDeleting} onClick={deleteProposal}>{deleteButtonText}</Button>}
                {isEditing ? 
                    <Button variant="contained" disabled={isSaving} onClick={updateProposal}>{buttonText}</Button>
                    :
                    <Button variant="contained" disabled={isSaving} onClick={createProposal}>{buttonText}</Button>
                }
              </Stack>
          </Box>

          <Modal
            open={linkModalOpen}
            onClose={handleLinkModalClose}
        >
            <Box sx={linkModalStyle}>
                <ProposalLinkForm links={links} setLinks={setLinks} handleLinkModalClose={handleLinkModalClose} />
            </Box>
        </Modal>
      </Box>
    );
  };
  
  export default ProposalForm;
  
import * as anchor from "@coral-xyz/anchor";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { ComputeBudgetProgram, Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import idl from "./idl/dao_voting.json";
import moment from "moment";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";

export const PROGRAM_ID = "9L7x6Eeh4yjFzFbCzNpZdo7nWaHd17oHoWPdBsT95VwG";
// export const ADMIN_PUBKEY = new anchor.web3.PublicKey('DAkpz8ywvDqk8WKfBACiF83Nbtcsw36hJJ9mUszaVBrq');

export const ADMIN_PUBKEY = new anchor.web3.PublicKey('CeTsWxrJT6v1JgcFGsg2uMdvmdJxxRLtAZ4T5DvGSjVm');

export const loadProgram = (
    connection: anchor.web3.Connection,
    wallet: AnchorWallet
  ): anchor.Program => {
    const provider = new anchor.AnchorProvider(connection, wallet, {
      preflightCommitment: "confirmed",
    });
  
    const votingProgramId = new PublicKey(PROGRAM_ID);
    const votingProgramInterface = JSON.parse(JSON.stringify(idl));
  
    const program = new anchor.Program(
      votingProgramInterface,
      votingProgramId,
      provider
    ) as anchor.Program;
  
    return program;
};

export const getNetworkConnection = (timeout = 30) => {
    const rpcUrl = process.env.REACT_APP_SOLANA_RPC_URL ?? 'https://api.devnet.solana.com';

    return new anchor.web3.Connection(rpcUrl, {
      confirmTransactionInitialTimeout: timeout * 1000, // timeout Seconds
      commitment: "finalized",
    });
};

export const fetchProposals = async(anchorProgram: anchor.Program, wallet: AnchorWallet) => {
    const proposals: any = await anchorProgram.account.proposal.all();
    const choices = await fetchChoices(anchorProgram, wallet);

    let metadata = await fetchMetadata(proposals);

    for (let i = 0; i < proposals.length; i++) {
      proposals[i].metadata = metadata[i] ?? {};

      proposals[i].choices = [];

      for (let k = 0; k < choices.length; k++) {
        if (choices[k].account.proposal.toString() === proposals[i].publicKey.toString()) {
          proposals[i].choices.push(choices[k]);
        }
      }

      proposals[i].choices.sort((a: any, b: any) => {
        if (a.metadata.title < b.metadata.title) {
          return 1;
        } else if (b.metadata.title < a.metadata.title) {
          return -1;
        }
  
        return 0;
      });
    }

    console.log(`raw proposals`, proposals);

    return proposals.sort((a: any, b: any) => {
      if (a.account.startTime < b.account.startTime) {
        return -1;
      } else if (b.account.startTime < a.account.startTime) {
        return 1;
      }

      return 0;
    });

}

export const fetchChoices = async(anchorProgram: anchor.Program, wallet: AnchorWallet) => {
    const choices: any = await anchorProgram.account.choice.all();
    let metadata = await fetchMetadata(choices);

    for (let i = 0; i < choices.length; i++) {
      choices[i].metadata = metadata[i];
    }

    console.log(`choices`, choices);

    return choices;
}

export const fetchVotes = async(anchorProgram: anchor.Program, wallet: AnchorWallet) => {
  const votes = await anchorProgram.account.vote.all();
  console.log(`votes`, votes);

  return votes;
}

const fetchMetadata = async(accountData: []) => {
  let metadataPromises = accountData.map((d: any) => fetch(d.account.metadataUri));
  return await Promise.all(metadataPromises)
    .then(results => Promise.all(results.map((r: any) => r ? r.json() : [])));
}

export const getProposalPDA = (title: string, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("proposal"), Buffer.from(title)],
    program.programId
  )
  );
}

export const getChoicePDA = (title: string, proposal: anchor.web3.PublicKey, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("choice"), proposal.toBuffer(), Buffer.from(title)],
    program.programId
  )
  );
}

export const buildInitProposalInstruction = async(anchorProgram: anchor.Program, proposalData: any) => {
  const [proposalPDA, proposalPDABump] = await getProposalPDA(proposalData.proposalTitle, anchorProgram);
  const verifiedCreator = new anchor.web3.PublicKey(proposalData.verifiedCreator);

  const ix = await anchorProgram.methods.initProposal(
    new anchor.BN(proposalData.startTime),
    new anchor.BN(proposalData.endTime),
    proposalData.proposalTitle,
    proposalData.metadataUri,
    proposalData.isHidden,
    new anchor.BN(proposalData.quorum),
    new anchor.BN(proposalData.requiredStakeDays)
  ).accounts({
      proposal: proposalPDA,
      verifiedCreator: verifiedCreator,
      admin: ADMIN_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  }).instruction();

  return ix;
}

export const buildUpdateProposalInstruction = async(anchorProgram: anchor.Program, proposalData: any) => {
  const [proposalPDA, proposalPDABump] = await getProposalPDA(proposalData.proposalTitle, anchorProgram);
  const verifiedCreator = new anchor.web3.PublicKey(proposalData.verifiedCreator);

  console.log({ proposalData });

  const ix = await anchorProgram.methods.updateProposal(
    new anchor.BN(proposalData.startTime),
    new anchor.BN(proposalData.endTime),
    proposalData.metadataUri,
    proposalData.isHidden,
    new anchor.BN(proposalData.quorum),
    new anchor.BN(proposalData.requiredStakeDays),
  ).accounts({
      proposal: proposalPDA,
      verifiedCreator: verifiedCreator,
      admin: ADMIN_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  }).instruction();

  return ix;
}

export const buildDeleteProposalInstruction = async(anchorProgram: anchor.Program, proposalData: any) => {
  const [proposalPDA, proposalPDABump] = await getProposalPDA(proposalData.proposalTitle, anchorProgram);

  const ix = await anchorProgram.methods.deleteProposal(
  ).accounts({
      proposal: proposalPDA,
      admin: ADMIN_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  }).instruction();

  return ix;
}


export const buildAddChoiceInstruction = async(anchorProgram: anchor.Program, choiceData: any) => {
  const [proposalPDA, proposalPDABump] = await getProposalPDA(choiceData.proposalTitle, anchorProgram);

  const [choicePDA, choicePDABump] = await getChoicePDA(choiceData.choiceTitle, proposalPDA, anchorProgram);

  const ix = await anchorProgram.methods.addChoice(
    choiceData.choiceTitle,
    choiceData.metadataUri,
  ).accounts({
      choice: choicePDA,
      proposal: proposalPDA,
      admin: ADMIN_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  }).instruction();

  return ix;
}


export const buildDeleteChoiceInstruction = async(anchorProgram: anchor.Program, choiceData: any) => {
  const [proposalPDA, proposalPDABump] = await getProposalPDA(choiceData.proposalTitle, anchorProgram);

  const [choicePDA, choicePDABump] = await getChoicePDA(choiceData.choiceTitle, proposalPDA, anchorProgram);
  console.log(`choice PDA`, choicePDABump);

  const ix = await anchorProgram.methods.deleteChoice(
  ).accounts({
      choice: choicePDA,
      proposal: proposalPDA,
      admin: ADMIN_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  }).instruction();

  return ix;
}

export const sendAndConfirmTransactionWithRetries = async (
  connection: Connection,
  transaction: anchor.web3.Transaction
): Promise<string | null> => {
  const TX_RETRY_INTERVAL = 2000;
  // console.log(`transaction`, transaction)
  // for (let i = 0; i < transaction.instructions.length; i++) {
  //   console.log(`instruction`, transaction.instructions[i].programId.toString());
  // }

  // const PRIORITY_FEE_IX = ComputeBudgetProgram.setComputeUnitPrice({microLamports: 30_000});
  // transaction.instructions.unshift(PRIORITY_FEE_IX);

  let blockhashResult = await connection.getLatestBlockhash({
    commitment: "confirmed",
  });

  let txSignature = null;
  let confirmTransactionPromise = null;
  let confirmedTx = null;

  const signatureRaw = transaction.signatures[0].signature;
  if (!signatureRaw) {
    throw new Error("Signature not found");
  }

  txSignature = bs58.encode(new Uint8Array(signatureRaw));

  let txSendAttempts = 1;
  try {
    // confirmTransaction throws error, handle it
    confirmTransactionPromise = connection.confirmTransaction(
      {
        signature: txSignature,
        blockhash: blockhashResult.blockhash,
        lastValidBlockHeight: blockhashResult.lastValidBlockHeight,
      },
      "confirmed"
    );

    await connection.sendRawTransaction(transaction.serialize(), { skipPreflight: true, maxRetries: 0 });

    confirmedTx = null;
    while (!confirmedTx) {
      confirmedTx = await Promise.race([
        confirmTransactionPromise,
        new Promise((resolve) =>
          setTimeout(() => {
            resolve(null);
          }, TX_RETRY_INTERVAL)
        ),
      ]);
      if (confirmedTx) {
        break;
      }

      await connection.sendRawTransaction(transaction.serialize(), { skipPreflight: true, maxRetries: 0 });

      console.log(`${new Date().toISOString()} Tx not confirmed after ${TX_RETRY_INTERVAL * txSendAttempts++}ms, resending`);
    }
  } catch (error) {
    console.error(error);
  }

  if (!confirmedTx) {
    console.log(`${new Date().toISOString()} Transaction failed`);
    throw new Error("Transaction failed");
  }

  console.log(`${new Date().toISOString()} Transaction successful`);

  return txSignature;
}

export const sendAndConfirmTransactionListCustom1 = async (
  wallet: AnchorWallet,
  connection: anchor.web3.Connection,
  transactionList: anchor.web3.Transaction[]
) => {
  const recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
  const transactionWithBlockhashList = transactionList.map((transaction) => {
    transaction.recentBlockhash = recentBlockhash;
    transaction.feePayer = wallet.publicKey;
    return transaction;
  });
  if (wallet.signAllTransactions) {
    const signedTransactionList = await wallet.signAllTransactions(
      transactionWithBlockhashList
    );
    const sendPromises = signedTransactionList.map(
      async (transaction) =>
        await sendAndConfirmTransactionWithRetries(connection, transaction)
    );
    await Promise.all(sendPromises);
  } else {
    for (const transaction of transactionWithBlockhashList) {
      const signedTransaction = await wallet.signTransaction(transaction);
      await sendAndConfirmTransactionWithRetries(connection, signedTransaction);
    }
  }
}

export const sendAndConfirmTransactionWithRetries_old = async (
  connection: Connection,
  transaction: anchor.web3.Transaction
) => {
  for (let i = 0; i <= 20; i++) {
    try {
      let txSign = undefined;
      try {
        txSign = await connection.sendRawTransaction(
          transaction.serialize(),
          {}
        );
      } catch (error) {
        console.log({ serializationError: error });
        throw error;
      }

      console.log(`Transaction sent(${i}): ${txSign}`);
      const result = await connection.confirmTransaction(txSign, "finalized");
      console.log({ result });
      break;
    } catch (error) {
      // @ts-ignore
      const errorMessage = error?.message;
      if (errorMessage) {
        if (
          errorMessage.includes("This transaction has already been processed")
        ) {
          break;
        }
        if (errorMessage.includes("Blockhash not found")) {
          throw error;
        }
      }
      // @ts-ignore
      console.log({ error, errorMessage: error?.message });
      if (i === 20) {
        throw error;
      }
    }
  }
};

export const formatTimestamp = (timestamp: any) => {
    return moment(timestamp * 1000).format('MM/DD/YYYY h:mma');
}
import { WalletMultiButton } from  "@solana/wallet-adapter-react-ui";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const Header = () => {
    return (
      <Box mt={3}>
          <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                  <Typography variant="h4" fontWeight={700}>VOTING ADMIN</Typography>
              </Box>
              <WalletMultiButton />
          </Box>
      </Box>
    );
  };
  
  export default Header;
  
// import * as anchor from "@coral-xyz/anchor";
import { Box, Button, Card, Link, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, Skeleton, Grid, CardContent, CardHeader, CardMedia } from "@mui/material"
import { useEffect, useState } from "react";
import { formatTimestamp } from "../utils/utils";
import ProposalCard from "./ProposalCard";
import EmptyProposalCard from "./EmptyProposalCard";

function LoadingSkeleton() {
    return <>
      <EmptyProposalCard />
      <EmptyProposalCard />
      <EmptyProposalCard />
      <EmptyProposalCard />
    </>;
}

const ChoiceButton = (props: any) => {
  return (
    <>
      {props.choice.metadata && <Box mt={1}>
        <Button variant="outlined" size="small" color="secondary" onClick={() => props.openUpdateChoiceModal(props.choice)}>{props.choice.metadata.title}</Button>
      </Box>}
    </>
  )
}

const ProposalList = (props: any) => {
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    if (props.proposals.length > 0) {
      console.log(`props`, props);
      
      let proposalChoices = prepareChoices();

      const data = props.proposals.map((proposal: any) => {
        return { 
          publicKey: proposal.publicKey.toString(),
          name: proposal.metadata?.title ?? proposal.account.title,
          subTitle: proposal.metadata?.subTitle ?? '',
          metadata: proposal.metadata,
          start: formatTimestamp(proposal.account.startTime.toNumber()),
          end: formatTimestamp(proposal.account.endTime.toNumber()),
          verifiedCreator: proposal.account.verifiedCreator.toString(),
          metadataUri: proposal.account.metadataUri.toString(),
          choices: proposal.choices,
          proposal: proposal.account,
        };
      });
      
      console.log(`proposals`, data);
      setProposals(data);
    }
  }, [props.proposals]);

  const prepareChoices = () => {
    let choices = props.choices ?? [];
    let proposalChoices: any = {};

    let votes = prepareVotes();

    for (let i = 0; i < choices.length; i++) {
      const proposal = choices[i].account.proposal.toString();
      if (!proposalChoices[proposal]) {
        proposalChoices[proposal] = [];
      }

      let choice = {...choices[i], votes: votes[choices[i].publicKey.toString()]};

      proposalChoices[proposal].push(choice);
    }

    return proposalChoices;
  }

  const prepareVotes = () => {
    let votes = props.votes ?? [];
    let proposalVotes: any = {};

    for (let i = 0; i < votes.length; i++) {
      const choice = votes[i].account.choice.toString();
      if (!proposalVotes[choice]) {
        proposalVotes[choice] = [];
      }

      proposalVotes[choice].push(votes[i]);
    }

    console.log(proposalVotes);
    return proposalVotes;
  }

  const getTotalVotes = (proposal: any) => {
    let totalVotes = 0;

    if (!proposal.choices) {
      return totalVotes;
    }

    for (let i = 0; i < proposal.choices.length; i++) {
      if (proposal.choices[i].votes) {
        totalVotes += proposal.choices[i].votes.length;
      }
    }

    return totalVotes;
  }

  const handleEditClick = (index: number) => {
    props.openEditModal(proposals[index]);
  }

  const handleEditChoiceClick = (choice: any) => {
    props.openUpdateChoiceModal(choice);
  }

    return (
      <div>
      <Grid container spacing={3}>
      {(!props.isLoading && proposals && proposals.length > 0) && proposals.map((row: any, index: number) => 
        <Grid item xs={6}>
          <ProposalCard 
            row={row} 
            openCreateChoiceModal={props.openCreateChoiceModal}
            handleEditProposalClick={handleEditClick} 
            handleEditChoiceClick={handleEditChoiceClick} 
            index={index} />
        </Grid>
      )}

          {props.isLoading && <>
            <LoadingSkeleton />
          </>}
      </Grid>
      
     
        
      </div>
    );
  };
  
  export default ProposalList;
  